import tinymce from 'tinymce'
import { Loading } from 'element-ui'
import { parseImgSrc } from '@/utils'
import { Pdf2Image } from '@/utils/utils/pdf2image.js'
import mammoth from 'mammoth';


const pdfSvg = `<svg height="25px" width="25px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
     viewBox="0 0 309.267 309.267" xml:space="preserve">
<g>
    <path style="fill:#E2574C;" d="M38.658,0h164.23l87.049,86.711v203.227c0,10.679-8.659,19.329-19.329,19.329H38.658
    c-10.67,0-19.329-8.65-19.329-19.329V19.329C19.329,8.65,27.989,0,38.658,0z"/>
    <path style="fill:#B53629;" d="M289.658,86.981h-67.372c-10.67,0-19.329-8.659-19.329-19.329V0.193L289.658,86.981z"/>
    <path style="fill:#FFFFFF;" d="M217.434,146.544c3.238,0,4.823-2.822,4.823-5.557c0-2.832-1.653-5.567-4.823-5.567h-18.44
    c-3.605,0-5.615,2.986-5.615,6.282v45.317c0,4.04,2.3,6.282,5.412,6.282c3.093,0,5.403-2.242,5.403-6.282v-12.438h11.153
    c3.46,0,5.19-2.832,5.19-5.644c0-2.754-1.73-5.49-5.19-5.49h-11.153v-16.903C204.194,146.544,217.434,146.544,217.434,146.544z
     M155.107,135.42h-13.492c-3.663,0-6.263,2.513-6.263,6.243v45.395c0,4.629,3.74,6.079,6.417,6.079h14.159
    c16.758,0,27.824-11.027,27.824-28.047C183.743,147.095,173.325,135.42,155.107,135.42z M155.755,181.946h-8.225v-35.334h7.413
    c11.221,0,16.101,7.529,16.101,17.918C171.044,174.253,166.25,181.946,155.755,181.946z M106.33,135.42H92.964
    c-3.779,0-5.886,2.493-5.886,6.282v45.317c0,4.04,2.416,6.282,5.663,6.282s5.663-2.242,5.663-6.282v-13.231h8.379
    c10.341,0,18.875-7.326,18.875-19.107C125.659,143.152,117.425,135.42,106.33,135.42z M106.108,163.158h-7.703v-17.097h7.703
    c4.755,0,7.78,3.711,7.78,8.553C113.878,159.447,110.863,163.158,106.108,163.158z"/>
</g>
</svg>`

const videoSvg = `<svg height="25px" width="25px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
     viewBox="0 0 303.188 303.188" xml:space="preserve">
    <g>
    <polygon style="fill:#E8E8E8;" points="219.821,0 32.842,0 32.842,303.188 270.346,303.188 270.346,50.525 "/>
    <g>
    <rect x="90.902" y="61.704" style="fill:#38A3FF;" width="119.89" height="119.89"/>
    <circle style="fill:#FFFFFF;" cx="150.847" cy="121.648" r="45.484"/>
    <path style="fill:#38A3FF;" d="M144.439,143.105c-4.145,2.682-7.537,0.837-7.537-4.1v-34.711c0-4.938,3.392-6.783,7.537-4.102
    l25.622,16.579c4.146,2.683,4.146,7.073,0,9.754L144.439,143.105z"/>
    </g>
    <polygon style="fill:#38A3FF;" points="227.64,25.263 32.842,25.263 32.842,0 219.821,0 "/>
    <g>
    <path style="fill:#A4A9AD;" d="M103.524,273.871l-9.813-34.557h-0.295c0.46,5.886,0.69,10.458,0.69,13.718v20.839H82.62v-47.979
    h17.262l10.009,34.065h0.263l9.813-34.065h17.295v47.979h-11.913v-21.036c0-1.094,0.017-2.308,0.049-3.643
    c0.033-1.334,0.181-4.605,0.443-9.813h-0.295l-9.681,34.491h-12.341V273.871z"/>
    <path style="fill:#A4A9AD;" d="M182.418,241.152c0,5.361-1.581,9.501-4.742,12.421c-3.162,2.921-7.652,4.381-13.472,4.381h-3.643
    v15.917h-12.963v-47.979h16.605c6.06,0,10.61,1.324,13.652,3.971C180.896,232.51,182.418,236.273,182.418,241.152z
     M160.562,247.387h2.362c1.947,0,3.495-0.546,4.643-1.641c1.149-1.094,1.724-2.604,1.724-4.529c0-3.238-1.795-4.857-5.383-4.857
    h-3.347L160.562,247.387L160.562,247.387z"/>
    <path style="fill:#A4A9AD;" d="M223.242,264.583h-5.283v9.288H205.16v-9.288h-19.133v-9.517l19.953-29.175h11.979v29.011h5.283
    L223.242,264.583L223.242,264.583z M205.16,254.902v-5.579c0-1.05,0.038-2.467,0.115-4.25c0.076-1.783,0.136-2.718,0.18-2.806
    h-0.36c-0.744,1.685-1.587,3.26-2.527,4.726l-5.316,7.909H205.16z"/>
    </g>
    <polygon style="fill:#D1D3D3;" points="219.821,50.525 270.346,50.525 219.821,0 "/>
    </g>
</svg>`

const wordSvg = `<svg height="25px" width="25px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
 viewBox="0 0 303.188 303.188" xml:space="preserve">
    <g>
    <polygon style="fill:#E8E8E8;" points="219.821,0 32.842,0 32.842,303.188 270.346,303.188 270.346,50.525 "/>
    <g>
    <rect x="90.902" y="61.704" style="fill:#004A94;" width="119.89" height="119.89"/>
    <rect x="101.303" y="72.105" style="fill:#FFFFFF;" width="99.089" height="99.087"/>
    <g>
    <path style="fill:#004A94;" d="M130.047,141.736l1.845-4.797l4.337-10.702l15.453-35.612h15.499v36.535
    c0,4.797-0.4,9.657-1.199,14.577c0.307-0.861,2.352-6.181,6.135-15.961l13.978-35.15h17.852l-29.292,67.441H153.39v-28.6
    c0-4.766,0.199-9.749,0.6-14.946c-1.016,4.09-2.83,9.456-5.443,16.099l-11.671,27.447h-20.021l-2.26-67.441h17.067l-0.461,33.813
    C131.016,132.649,130.631,138.415,130.047,141.736z"/>
    </g>
    </g>
    <polygon style="fill:#004A94;" points="227.64,25.263 32.842,25.263 32.842,0 219.821,0 "/>
    <g>
    <path style="fill:#A4A9AD;" d="M123.511,248.864c0,8.007-2.205,14.177-6.613,18.509s-10.605,6.498-18.591,6.498H82.784v-47.979
    H99.39c7.701,0,13.646,1.969,17.836,5.907C121.415,235.737,123.511,241.426,123.511,248.864z M110.056,249.324
    c0-4.398-0.87-7.657-2.609-9.78c-1.739-2.122-4.381-3.183-7.926-3.183h-3.773v26.877h2.888c3.938,0,6.826-1.143,8.663-3.43
    C109.136,257.523,110.056,254.028,110.056,249.324z"/>
    <path style="fill:#A4A9AD;" d="M177.003,249.816c0,8.073-1.98,14.21-5.939,18.411c-3.961,4.201-9.758,6.301-17.395,6.301
    c-7.525,0-13.297-2.111-17.311-6.333c-4.016-4.222-6.021-10.371-6.021-18.444c0-7.985,1.996-14.083,5.988-18.295
    c3.992-4.211,9.797-6.317,17.41-6.317c7.635,0,13.422,2.089,17.361,6.268C175.034,235.585,177.003,241.721,177.003,249.816z
     M143.989,249.816c0,9.276,3.227,13.915,9.68,13.915c3.283,0,5.717-1.126,7.303-3.38c1.586-2.253,2.379-5.765,2.379-10.534
    c0-4.791-0.805-8.33-2.412-10.617c-1.607-2.286-4.01-3.429-7.203-3.429C147.237,235.77,143.989,240.452,143.989,249.816z"/>
    <path style="fill:#A4A9AD;" d="M207.031,235.835c-3.085,0-5.492,1.264-7.221,3.791c-1.728,2.527-2.592,6.011-2.592,10.452
    c0,9.233,3.511,13.849,10.534,13.849c2.122,0,4.179-0.295,6.17-0.886c1.99-0.591,3.992-1.302,6.005-2.133v10.961
    c-4.003,1.772-8.532,2.658-13.586,2.658c-7.242,0-12.794-2.1-16.655-6.301c-3.861-4.201-5.792-10.272-5.792-18.214
    c0-4.966,0.935-9.331,2.806-13.094s4.562-6.656,8.073-8.68c3.512-2.023,7.641-3.036,12.389-3.036
    c5.186,0,10.141,1.127,14.866,3.38l-3.971,10.206c-1.772-0.831-3.544-1.531-5.316-2.1
    C210.969,236.12,209.066,235.835,207.031,235.835z"/>
    </g>
    <polygon style="fill:#D1D3D3;" points="219.821,50.525 270.346,50.525 219.821,0 "/>
    </g>
</svg>
`

export const uploadPdfPlugin = createPlugin('uploadPdf', pdfSvg, '.pdf', pdfHandler)
export const uploadVideoPlugin = createPlugin('uploadVideo', videoSvg, '.mp3, .mp4, .avi, .mkv, .wmv、.mov、.flv', videoHandler)
export const uploadWordPlugin = createPlugin('uploadWord', wordSvg, '.doc, .docx', wordHandler)


function loadingHandler(option = { fullscreen: true }) {
  const loadingInstance = Loading.service(option)
  return function () {
    loadingInstance.close()
  }
}

function createInput(accept) {
  const input = document.createElement('input')
  input.setAttribute('type', 'file')
  input.setAttribute('accept', accept)
  return input
}

async function uploadToOss(file) {
  try {
    const uploadedObj = await $uploadOSSPics([
      {
        files: [file],
        prop: 'imagePath',
        dirPrefix: $ossDirMapWithType['0'],
        uuidPrefix: 'test'
      }
    ])
    if (!uploadedObj) return [true, null]
    const url = `${process.env.VUE_APP_OSS_BASE_URL}${parseImgSrc(uploadedObj.imagePath)}`
    return [false, url]
  } catch {
    return [true, null]
  }
}

function concatContent(addContent = '', wrap = true) {
  const activeEditor = tinymce.activeEditor
  const char = wrap ? '\n' : ''
  activeEditor.insertContent([addContent].join(char))
}

export function createPlugin(name, svg, accept, callback) {
  return function (editor, url) {
    editor.ui.registry.addButton(name, {
      text: svg,
      onAction: function () {
        const input = createInput(accept)
        input.click()
        input.onchange = async function () {
          const file = this.files[0]
          const close = loadingHandler()
          const [err, url] = await uploadToOss(file)
          // const [err, url] = [false, URL.createObjectURL(file)]
          close()
          if (err) return
          callback(file, url)
        }
      }
    })
  }
}

async function pdfHandler(file, path) {
  const pdf2img = await Pdf2Image.open(path)
  const page = pdf2img.numPages()
  for (let i = 1; i <= page; i++) {
    let c
    const url = await pdf2img.getImageDataUrl(i, {
      scale: 96 / 72 * 2,
      callback: ({ canvas }) => {
        c = canvas
      }
    })
    concatContent(`<img width="${c.width / 2}" height="${c.height / 2}"  data-pdf="pdf" src="${url}" ></img>`)
  }
}


function videoHandler(file, path) {
  concatContent(`<video controls name="media"><source src="${path}" :type="${file.type}"></video>`)
}

async function wordHandler(file) {
  const reader = new FileReader()
  reader.onload = async (event) => {
    const arrayBuffer = event.target.result
    const res = await mammoth.convertToHtml({ arrayBuffer })
    if (!res) return
    concatContent(res.value)
  }
  reader.readAsArrayBuffer(file)
}
